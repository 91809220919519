import React from 'react';
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SwiperCore, {  Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as styles from '../styles/pages/index.module.css'
import Seo from '../components/Seo'

SwiperCore.use([Autoplay, EffectFade]);

export default ({data}) => {
    // console.log(data, 'section')
    return (
        <Layout>
            <Seo 
                title = {data.contentfulSection.title}
            />
        <h1 className="visually-hidden">
            {data.contentfulSection.title}
        </h1>
        <Swiper
                className={styles.swiper}
                slidesPerView={1}
                autoplay
                delay = {3000}
                reverseDirection = {false}
                // loop
                fadeEffect
        >
            {
                data.contentfulSection.projects.map(project => {
                    return (
                        <SwiperSlide
                            key={`${project.title}`}
                            className={styles.slide}
                        >
                            <Link
                            to={`/${data.contentfulSection.slug}/${project.slug}`}
                            className={styles.link}
                            >
                            <Img 
                                className={styles.image}
                                fluid={project.heroImage.fluid}
                                alt={project.heroImage.title}
                            />
                            </Link>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>

        </Layout>

    )
}

export const sectionQuery = graphql`
    query sectionQuery($slug: String!) {
        contentfulSection(slug: { eq: $slug }) {
            title
            slug
            projects {
            title
            slug
            heroImage {
                title
                fluid(maxWidth: 1000) {
                    ...GatsbyContentfulFluid_tracedSVG
                }
            }
            }
        }

    }
`
